import React from 'react';
import './App.css';
import {OfferTransfer} from "./pages/OfferTransfer";
import {FcmTokenProvider} from "./closures/getUserFCMToken";


function App() {
  return (
      <FcmTokenProvider>
        <OfferTransfer/>
      </FcmTokenProvider>
  );
}

export default App;
