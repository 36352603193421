import React from "react";
import {useUaParser} from "../hooks/useUaParser";
import {sendEventOpen} from "../closures/sendEvent";
// import {getHostDocumentId} from "../closures/getHostDocumentId";
import {refineCookies} from "../closures/refineCookies";
import Cookies from "js-cookie";
import {SymbolsList} from "../enums/SymbolsList";
import {RerouteWrapper} from "./RerouteWrapper";
import AtomicSpinner from "atomic-spinner";
// import {refineSearchParams} from "../hooks/refineSearchParams";

const AtomicSpinnerConst = (reason = "") => (
    <div style={{
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        display: "flex",
        height: "100vh",
    }}><AtomicSpinner/><span>{reason}</span></div>
);


export const OfferTransfer = () => {
    const {uaString, locale} = useUaParser();
    // const {searchParams} = refineSearchParams();
    // const {isPreview} = getHostDocumentId();
    const hasInstalled = Cookies.get(SymbolsList.hasInstalled);
    const uuid = Cookies.get(SymbolsList.uuid) || "";
    const pwaId = Cookies.get(SymbolsList.pwaid) || "";
    const acid = Cookies.get(SymbolsList.acid) || "";
    const offerWithParams = Cookies.get(SymbolsList.offer);

    // if (searchParams.close) {
    //     console.log("We are closing");
    //     window.close();
    // }

    if (offerWithParams && (hasInstalled || window.matchMedia('(display-mode: fullscreen)').matches)) {
        (async () => {
            console.log('Requesting permission...');
            const permission = await window.Notification.requestPermission();
            if (permission === 'granted') {
                console.log('Notification permission granted.');
            } else {
                console.log('Notification permission denied.');
            }

            const registration = await window?.navigator?.serviceWorker?.ready;
            // console.log(registration?.active);
            registration?.active?.postMessage({uuid, id: pwaId, uid: acid});

            // if (window.location.host === "spacejourney.fun") {
            //     console.log("window.location.host", window.location.host);
            //     registration?.active?.postMessage({uuid, openRandomUrl: true});
            // }

            await sendEventOpen({
                uuid,
                pwaId,
                fbc: refineCookies().fbc,
                fbp: refineCookies().fbp,
                ua: uaString,
                locale,
                acid,
            });

            if (offerWithParams) {
                window.open(offerWithParams, "_self");
                // window.location.replace(window.location.origin + "?close=1");

                // setInterval(() => {
                //     console.log("You outside the scope");
                // }, 1000);

                // setInterval(() => {
                //     window.location.href = offerWithParams;
                // }, 5000);

                setTimeout(() => {
                    // window.close();
                    //
                    registration?.active?.postMessage({terminate: true});
                    console.log("sent terminate to worker");
                }, 3000);
            }
        })();

        return AtomicSpinnerConst("Going to a page");
    }

    return <RerouteWrapper/>;
};
