
interface EventProps {
    uuid: string,
    pwaId: string,
    fbc: string,
    fbp: string,
    ip: string,
    country: string,
    ua: string,
    locale: string,
    acid: string,
    fcmToken: string,
}

interface CollectProps extends EventProps {
    event: "push" | "install" | "open",
}

const collectParams = async ({uuid, pwaId, fbc, fbp, ip, country, ua, event, locale, acid, fcmToken}: CollectProps) => {
    const getParams = new URLSearchParams();
    getParams.set("user_id", uuid);
    getParams.set("pwaId", pwaId);
    getParams.set("fbc", fbc);
    getParams.set("fbp", fbp);
    getParams.set("ip", ip);
    getParams.set("country", country);
    getParams.set("ua", ua);
    getParams.set("event", event);
    getParams.set("locale", locale);
    getParams.set("acid", acid);
    getParams.set("fcmToken", fcmToken);

    const postbackTemplate = `https://api.pwa.bot/postback/?${getParams.toString()}`;
    const sendResult = fetch(postbackTemplate);
    console.log("sendEvent" + event[0].toUpperCase() + event.substr(1), await sendResult);

    return getParams;
};

export const sendEventInstall = async (props: EventProps): Promise<any> => {
    return collectParams({...props, event: "install"});
};

export const sendEventOpen = async (props: any): Promise<any> => {
    return collectParams({...props, event: "open"});
};

export const sendEventPush = async (props: EventProps): Promise<any> => {
    return collectParams({...props, event: "push"});
};
