import React, {useEffect, useState} from "react";
import index0 from "!!raw-loader!./whites/landing-1/index.html"; // eslint-disable-line
import index1 from "!!raw-loader!./whites/landing-2/index.html"; // eslint-disable-line
import index2 from "!!raw-loader!./whites/landing-3/index.html"; // eslint-disable-line
import index3 from "!!raw-loader!./whites/landing-4/index.html"; // eslint-disable-line
import index4 from "!!raw-loader!./whites/landing-5/index.html"; // eslint-disable-line
import index5 from "!!raw-loader!./whites/landing-6/index.html"; // eslint-disable-line
import index6 from "!!raw-loader!./whites/landing-7/index.html"; // eslint-disable-line
import index7 from "!!raw-loader!./whites/landing-8/index.html"; // eslint-disable-line
import index8 from "!!raw-loader!./whites/landing-9/index.html"; // eslint-disable-line
import index9 from "!!raw-loader!./whites/landing-10/index.html"; // eslint-disable-line


interface DynamicWhitePageProps
{
    index: number,
    appDetails: object
}


const retrieveSentencesSource = (description, count, offsetCounter = 0) => {
    const countInt = parseInt(count);
    const descriptionSet = description.replace(/\n/g, "").split(".");
    const sentences = descriptionSet.slice(offsetCounter, offsetCounter + countInt);
    let allSentences: Array<string> = [...sentences];
    let rewind = false;

    if (sentences.length < countInt) {
        allSentences = [...sentences, ...descriptionSet.slice(0, countInt - sentences.length)];
        rewind = true;
    }

    return {result: allSentences.join("."), rewind};
};

const carryCounter = (func) => {
    let counter = 0;

    return (description, count) => {
        const countInt = parseInt(count);
        const {result, rewind} = func(description, countInt, counter);
        counter = rewind ? countInt : counter + countInt;

        return result;
    };
};

const retrieveSentences = carryCounter(retrieveSentencesSource);

const pages: Array<string> = [];
pages.push(index0);
pages.push(index1);
pages.push(index2);
pages.push(index3);
pages.push(index4);
pages.push(index5);
pages.push(index6);
pages.push(index7);
pages.push(index8);
pages.push(index9);

export const DynamicWhitePage = ({index, appDetails}: DynamicWhitePageProps) => {
    const [pageHtml, setPageHtml] = useState(null);
    // let pageHtml = pages[index] || pages[0];

    useEffect(() => {
        if (typeof appDetails === "object" && !pageHtml) {
            let pageHtmlEffect = pages[index] || pages[0];
            appDetails.updated = appDetails.updated
                ? new Date(appDetails.updated).toString().substr(0, 15)
                : new Date().toString().substr(0, 15);
            appDetails.developerTitle ="Developer";
            appDetails.installsTitle ="Installs";
            appDetails.comingOutTitle ="Comping out";
            appDetails.lastUpdateTitle = "Last update";
            appDetails.featuresTitle = "Features";
            // console.log("appDetails", appDetails);
            Object.entries(appDetails)?.forEach(entry => {
                // console.log(`{${entry[0]}}`, entry[1]);

                if (Array.isArray(entry[1])) {
                    entry[1].forEach((item, index) => {
                        pageHtmlEffect = pageHtmlEffect.replace(`{${entry[0]}[${index}]}`, item);
                    });
                } else {
                    pageHtmlEffect = pageHtmlEffect.replace(new RegExp(`{${entry[0]}}`, "g"), entry[1]);
                }
            });

            while (pageHtmlEffect.includes("dummyText")) {
                const matchList = pageHtmlEffect.match(/dummyText\((\d+)\)/);

                if (matchList) {
                    const sentenceNumber = matchList[1];
                    const stringLine = retrieveSentences(appDetails.description, sentenceNumber);
                    pageHtmlEffect = pageHtmlEffect.replace(`{dummyText(${sentenceNumber})}`, stringLine);
                } else {
                    break;
                }
            }

            setPageHtml(pageHtmlEffect);
        }
    }, [appDetails, pageHtml, index]);

    if (!pageHtml) {
        return (<></>);
    }

    return <div dangerouslySetInnerHTML={{__html: pageHtml }}></div>;
};
