// import {useEffect} from "react";
import {UAParser} from "ua-parser-js";

interface useRedirectParams
{
    fbp: string,
    fbc: string,
}

export const doRedirect = ({fbp, fbc}: useRedirectParams) => {
    // useEffect(() => {
        setTimeout(() => {
            const uaParser = new UAParser();
            uaParser.setUA(window.navigator.userAgent);
            // console.log("UAParser.getBrowser()", uaParser.getBrowser());
            // uaParser.setUA(`Mozilla/5.0 (Linux; Android 12; moto g22 Build/STAS32.79-77-28-63-1; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Yoda/oversea-3.1.1-rc6-cache ISDR/0 Kwai_Pro/10.5.10.535603 NetScore/44 NST/F deviceScore/26 webviewPreloaded/false WebViewPreAlloc/0...`);
            // console.log("UAParser.getBrowser()", uaParser.getBrowser());
            // uaParser.setUA(`Mozilla/5.0 (Linux; Android 10; Redmi Note 8 Build/QKQ1.200114.002; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Yoda/oversea-3.1.1-rc6-cache ISDR/0 Kwai_Pro/10.4.40.535503 NetScore/63 NST/F deviceScore/33 webviewPreloaded/true WebViewPreAlloc/0 S...`);
            // console.log("UAParser.getBrowser()", uaParser.getBrowser());
            // uaParser.setUA(`Mozilla/5.0 (Linux; Android 14; SM-M546B Build/UP1A.231005.007; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/125.0.6422.165 Mobile Safari/537.36 Yoda/oversea-3.1.1-rc6-cache ISDR/0 Kwai_Pro/10.4.40.535503 NetScore/16 NST/S deviceScore/104 webviewPreloaded/true WebViewPreAlloc/0 Stat...`);
            // console.log("UAParser.getBrowser()", uaParser.getBrowser());
            // uaParser.setUA(`Mozilla/5.0 (Linux; Android 12; moto e22 Build/SOWS32.121-56-15; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/124.0.6367.179 Mobile Safari/537.36 Yoda/oversea-3.1.1-rc6-cache ISDR/0 Kwai_Pro/10.4.40.535503 NetScore/76 NST/F deviceScore/25 webviewPreloaded/true WebViewPreAlloc/1 Stat...`);
            // console.log("UAParser.getBrowser()", uaParser.getBrowser());
            // uaParser.setUA(`Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Mobile Safari/537.36`);
            // console.log("UAParser.getBrowser()", uaParser.getBrowser());
            if ("Chrome" !== uaParser.getBrowser().name && "Safari" !== uaParser.getBrowser().name) {
                const urlObject = new URL(window.location.href);
                urlObject.searchParams.set("_fbp", fbp);
                urlObject.searchParams.set("_fbc", fbc);

                /*const a = window.document.body.appendChild(window.document.createElement("a"));
                a.setAttribute("href", `intent://${urlObject.hostname}/?${urlObject.searchParams.toString()}#Intent;scheme=https;package=com.android.chrome;end;`);
                a.click();*/

                // window.location.href = `intent://${urlObject.hostname}/?${urlObject.searchParams.toString()}#Intent;scheme=https;package=com.android.chrome;end;`;
                window.location.href = `intent://navigate?url=${window.location.hostname}/?${urlObject.searchParams.toString()}#Intent;scheme=googlechrome;end;`;
            }
        }, 1000);
    // }, [fbp, fbc]);
};
